import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Blockchain training</h2>
        <p>Learn fast</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">

          <h3>Program</h3>
          <p>

          <header>
						<h4>Heading with a Subtitle</h4>
						<p>Lorem ipsum dolor sit amet nullam id egestas urna aliquam</p>
					</header>
          A brief history of Money
          What is Bitcoin
          Basic cryptography 
          Understanding Blockchain
          Cryptocurrencies 
          tokenomics
          CryptoMarkets
          ICO fundraising

           
         Workshop
          
         Protecting your Digital Assets
         Trading cryptocurrencies
         Building your first crypto Assets on Blockchain 
         Using DeFi Tools, an overview of Decentralized



         
          </p>

          

          <p>
            Vestibulum ultrices risus velit, sit amet blandit massa auctor sit
            amet. Sed eu lectus sem. Phasellus in odio at ipsum porttitor mollis
            id vel diam. Praesent sit amet posuere risus, eu faucibus lectus.
            Vivamus ex ligula, tempus pulvinar ipsum in, auctor porta quam.
            Proin nec dui cursus, posuere dui eget interdum. Fusce lectus magna,
            sagittis at facilisis vitae, pellentesque at etiam. Quisque posuere
            leo quis sem commodo, vel scelerisque nisi scelerisque. Suspendisse
            id quam vel tortor tincidunt suscipit. Nullam auctor orci eu dolor
            consectetur, interdum ullamcorper ante tincidunt. Mauris felis nec
            felis elementum varius.
          </p>

          <hr />

          <h4>Feugiat aliquam</h4>
          <p>
            Nam sapien ante, varius in pulvinar vitae, rhoncus id massa. Donec
            varius ex in mauris ornare, eget euismod urna egestas. Etiam lacinia
            tempor ipsum, sodales porttitor justo. Aliquam dolor quam, semper in
            tortor eu, volutpat efficitur quam. Fusce nec fermentum nisl. Aenean
            erat diam, tempus aliquet erat.
          </p>

          <p>
            Etiam iaculis nulla ipsum, et pharetra libero rhoncus ut. Phasellus
            rutrum cursus velit, eget condimentum nunc blandit vel. In at
            pulvinar lectus. Morbi diam ante, vulputate et imperdiet eget,
            fermentum non dolor. Ut eleifend sagittis tincidunt. Sed viverra
            commodo mi, ac rhoncus justo. Duis neque ligula, elementum ut enim
            vel, posuere finibus justo. Vivamus facilisis maximus nibh quis
            pulvinar. Quisque hendrerit in ipsum id tellus facilisis fermentum.
            Proin mauris dui, at vestibulum sit amet, auctor bibendum neque.
          </p>
        </div>



        
      </section>
    </article>
  </Layout>
);

export default IndexPage;
